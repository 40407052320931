import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Layout from "../layouts";
import GlobalStyles from "../components/GlobalStyles";

const PrivacyPage = () => (
  <Layout>
    <Privacy>
      <GlobalStyles />
      <Helmet>
        <title>Tmzn - Privacy Policy</title>
        <link rel="shortcut icon" href="/images/favicon.png" />
      </Helmet>
      <h2>Privacy Policy</h2>

      <h3>What information does the Tmzn obtain and how is it used?</h3>
      <p>
        We respect your privacy. Tmzn does not collect or transmit any
        personally identifiable information about you, such as your name,
        address, phone number or email address.
      </p>

      <h3>Why does the app ask for access to my calendar?</h3>
      <p>
        Access to the device's calendar is required to add events. No calendar
        data is transmitted or stored.
      </p>

      <h3>How do you handle location data?</h3>
      <p>
        Tmzn does not use or collect any data related to your geographic
        location. Tmzn &nbsp;<em>does</em>&nbsp;use your device's public IP
        address to guess your closest location in order to pre-load a timezone
        that's relevant to you.
      </p>

      <h3>What tracking information do you collect?</h3>
      <p>
        Tmzn uses a third party tracking vendor (Segment.com) to track some
        actions within Tmzn. These actions are tracked in order to gather
        feedback in order to develop features that are more useful. These
        actions are anonymized and therefore are not identifiable to you
        personally.
      </p>

      <h3>Can users see their personal data?</h3>
      <p>Tmzn itself does not collect, transmit, or maintain user data.</p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us.
      </p>
    </Privacy>
  </Layout>
);

const Privacy = styled.div`
  padding: 16px;
  background-color: #fff;

  h1 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1.1em;
  }

  p {
    font-size: 1em;
  }
`;

export default PrivacyPage;
